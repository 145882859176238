import * as React from "react"
import Layout from '../components/Layout'
// markup
const TreatmentPage = () => {
  return (
    <Layout>
      <div class = "content">
      <h1>Anxiety</h1>
      <p>Feeling out of control of your own thoughts and emotions is torture. Anxiety hijacks your brain, takes over your perception and impacts your physical body and mind. You may find yourself stuck in certain ways of thinking and unsure how to get back to feeling at ease. Anxiety can be managed through re-training your thinking and with the development of coping skills. It is possible to regain control f of your thoughts and feelings again with help.</p>
      <br/>
      <h1>PTSD</h1>
      <p>After going through one or more traumatic experiences in your life, you may experience the symptoms of Post Traumatic Stress Disorder. Some of these symptoms include relationship issues, flashbacks, nightmares, anger, depression, anxiety, substance abuse, sexuality issues, eating disorders, health/pain issues, paranoia, hyper-vigilance, periods of feeling disconnected to your body, and panic among others. At times such symptoms may seem unbearable and may make you feel as though you are going "crazy". You may worry that no one will understand what you are going through and that you may be judged by others if you talk about what you are experiencing. Please know that such a response to trauma is not something to feel ashamed about and you are not alone. </p>
      <br/>
      <h1>Adult survivors of childhood abuse</h1>
      <p>Abuse comes in many forms. Oftentimes childhood abuse/neglect/maltreatment inflicts emotional wounds that remain present in adulthood. Even without the abuse in the front of your awareness, it may subconsciously impact your day to day life in mind body and soul. Some go a long time, even a lifetime, without speaking of the abuse. Keeping these secrets can be toxic and although avoidance of these issues may feel comfortable, they will likely not resolve without working through them with a safe person, preferably a therapist. I will provide you with a safe, non-judgmental, empathetic ear and will always leave you in control of what is discussed. If you are ready to get well and address your trauma(s), even if you don't feel ready to speak about the specifics of your abuse, please know that healing is possible through therapy.</p>
      <br/>
      <h1>Substance abuse</h1>
      <p>Substance abuse is progressive. It may start off small and then grow into something that is overwhelming. You may begin using substances to relieve pain, get some sleep, feel good, relax, unwind, numb yourself, or even socialize. As time goes on, you may begin to use more of the substance, in larger amounts, more frequently then when you started, or you may move on to different, more dangerous substances. It may begin to impact more things in your life such as relationships, legal issues, health, your job. Many people try to control their use without the help of others before it spirals out of control. It is important to remember, addiction is not a moral failing. If you have tried to stop or control your use on your own and have been unsuccessful, you may need some help. </p>
      <br/>
      <h1>Depression</h1>
      <p>Depression kills your motivation and drive, impacts your sleep and eating habits, effects your physical body and takes over your life. Depression can be very isolating and may make you feel very helpless, with very little hope for the future. It sometimes comes on with no explanation and impacts all aspects of your life. It is so common, yet many people feel afraid or ashamed to talk about it. If you are experiencing depression, it may be time to stop the suffering, get some help, start feeling better and start enjoying life again.</p>
      <br/>
      </div>    
    </Layout>
  )
}

export default TreatmentPage